import React, {Component} from 'react';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

import Calculator from './components/Calculator'
import Homepage from './components/Homepage'
import Contact from './components/Contact'
import AboutUs from './components/AboutUs'

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ul className="navbar-nav mr-auto">
            <li><Link to={'/'} className="nav-link"> Home </Link></li>
            <li><Link to={'/contact'} className="nav-link">Contact</Link></li>
            <li><Link to={'/about-us'} className="nav-link">About Us</Link></li>
            <li><Link to={'/calculator'} className="nav-link">Calculator</Link></li>
          </ul>
          </nav>
          <hr />
          <Switch>
              <Route exact path='/' component={Homepage} />
              <Route path='/contact' component={Contact} />
              <Route path='/about-us' component={AboutUs} />
              <Route path='/calculator' component={Calculator} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;