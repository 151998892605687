import React from 'react'
import styled from 'styled-components';
import './style.less'
import Calculator from './Calculator'

import book1 from './images/book1.jpg';
import book2 from './images/book2.jpg';
import book3 from './images/book3.jpg';
import book4 from './images/book4.jpg';
import book5 from './images/book5.jpg';
import book6 from './images/book6.jpg';
import book7 from './images/book7.jpg';
import book8 from './images/book8.jpg';
import book9 from './images/book9.jpg';

const Title = styled.p`
	color: #444;
	font-size  37px;
	font-weight: bold;
	margin: 0px auto 40px;
	width: 100%;
`;

const Paragraph = styled.p`
	color: #444;
	font-size: 24px;
	margin: 1.5rem;
`;

const ParagraphSmall = styled.p`
	color: #444;
	font-size: 18px;
	margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`;

const ImagesWrapper = styled.div`
  text-align: center;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 5%;

  & > img{
    width: 300px;
    height: 390px;
    padding: 10px;
  }
`;

class Homepage extends React.Component {

  render() {
    return (
    <div>
      <div className="container text-center">
        <Title>Corneal Astigmatism Calculator</Title>
        <Paragraph>This program calculates the corneal astigmatism, based on measurements from various corneal topography and biometry devices.</Paragraph>
        <ParagraphSmall>Based on surgeon’s preference, a statistical weigh factor can be attributed to each device, and the resultant corneal astigmatism determined.</ParagraphSmall><br />
        <ParagraphSmall><b>Dr. Ming Wang, MD, PhD, Dr. Joshua Frenkel, MD, MPH<br />Drs. Sarah Connolly, OD, Marianne Johnson, OD, and Nathan Rock, OD</b></ParagraphSmall>
      </div>

      <ImagesWrapper>
        <img src={book1} alt="" />
        <img src={book2} alt="" />
        <img src={book3} alt="" />
        <img src={book4} alt="" />
        <img src={book5} alt="" />
        <img src={book6} alt="" />
        <img src={book7} alt="" />
        <img src={book8} alt="" />
        <img src={book9} alt="" />
      </ImagesWrapper>

      <div className="container text-center">
        <Paragraph><b>Wang Vision Institute</b> <a href="http://www.wangvisioninstitute.com">(see here)</a></Paragraph>
        <ParagraphSmall>1801 West End Ave Ave, Ste 1150, Nashville, TN, 37203, USA <br/>615-321-8881 (O), 615-321-8874(Fax)</ParagraphSmall>
        <ParagraphSmall><a href="http://www.wangvisioninstitute.com">www.wangvisioninstitute.com</a></ParagraphSmall>
      </div>

      <Calculator/>

    </div>
    )
  }
}

export default Homepage