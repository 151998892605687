import React, {Component} from 'react';
import styled from 'styled-components';

const Container = styled.div`
	background-color: #fff;
	box-shadow: 0 0px 15px 0 rgba(0, 0, 0, .25);
	height: auto;
	margin: 30px auto;
	padding: 25px 0 35px 35px;
	width: 75%;
`;

const Title = styled.p`
	color: #444;
	font-size  37px;
	font-weight: bold;
	margin: 40px 15% 50px auto;
	width: 77%;
`;

const Paragraph = styled.p`
	color: #444;
	font-size: 24px;
	margin: 0;
`;

const Paragraph2 = styled.p`
	color: #444;
	font-size: 18px;
	margin: 0;
	margin-bottom: 0.5rem;
`;

const Row = styled.div`
	width: 100%;
	display: block;
	@media screen and (min-width: 767px) {
		display: flex;
	}
`;

const HalfRow = styled.div`
	width: 45%;
	margin-right: 5%;

	@media screen and (max-width: 767px) {
		width: 75%;
		margin-right 0;
	}

	@media screen and (max-width: 550px) {
		width: 90%;
	}
`;

const QuarterRow = styled.div`
	width: 21%;
	margin-right: 4%;

	@media screen and (max-width: 950px) {
		width: 45%;
	}
	@media screen and (max-width: 767px) {
		width: 75%;
	}
	@media screen and (max-width: 550px) {
		width: 90%;
	}
`;

const Label = styled.p`
	color: #555;
	font-size: 12px;
	margin: 25px 0 3px 0;

	width: ${props => props.width};
`;

const Input = styled.input`
    background: #f9f9f9;
    border: 2px solid #eee;
    border-radius: 3px;
    height: 45px;
    padding-left: 15px;
	width: 100%;
	width: ${props => props.width};

	&:focus {
		outline: none;
	}
`;

const ShortInput = styled(Input)`
	height: 30px;
    margin: auto 0 auto 15px;
    width: 15%;
	width: ${props => props.width};
`;

const LabelRadio = styled.label`
	display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 10px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 15px;
    color: #555;
    line-height: 1.8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: ${props => props.width};

    & > input {
    	position: absolute;
	    opacity: 0;
	    cursor: pointer;
    }

    & > span {
    	position: absolute;
	    top: 0;
	    left: 0;
	    height: 23px;
	    width: 23px;
	    background-color: #eee;
	    border-radius: 50%;
	    border: 2px solid #ddd;
    }

    &:hover input ~ span {
		background-color: #ccc;
	}

	& > input:checked ~ span {
	    background-color: #fff;
		border: 2px solid #ccc;
	}

	& > span:after {
	    content: "";
	    position: absolute;
	    display: none;
	}

	& > input:checked ~ span:after {
	    display: block;
	}

	& > span:after {
	 	top: 8px;
		left: 8px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #2196F3;
	}
`;

const Button = styled(Container)`
	background-color: #00b8ff;
	color: #fff;
	padding: 1px 17px;
	text-align: center;

	& > p {
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1.5px;
	}
`;

const Select = styled.select`
	background-color: #f9f9f9;
	border: 2px solid #eee;
	color: #333;
	font-size: 17px;
	height: 50px;
	padding-left: 15px;
	width: 104%;

	&:focus {
		outline: none;
	}
`;

const EyeResults = styled.div`
	font-size: 14px;
	color: #777;
	font-weight: bold;

	&:hover {
		color: #444;
	}
`;

const ResultsInfo = styled.p`
	font-size: 14px;
	color: #777;
	font-weight: ${props => props.bold};
`;

const Separator = styled.p`
	border: 0.7px solid #ddd;
	height: 0;
	margin: 2rem 0;
	width: 97%;
`;

const PrintButton = styled.a`
	color: #444;
	text-decoration: none;
	position: absolute;
    top: 15%;
    right: 10%;
    font-size: 24px;

	&:hover {
		color: #444;
		text-decoration: none;
	}
`;

const Error = styled.p`
	background-color: #ffdada;
	color: red;
	font-size: 14px;
	font-weight: bold;
	height: 40px;
    line-height: 40px;
    margin: 2rem 0;
	text-align: center;
	width: 97%;
`;

const NameLabel = styled.p`
	color: #444;
	font-size: 18px;
`;

const Button2 = styled(Button)`
	background: #f9f9f9;
    border: 2px solid #eee;
    border-radius: 3px;
	color: #555;
	height: auto;
    padding: 0;
	margin: 20px 40px;
	text-align: center;
	width: auto;

	& > p {
		font-size: 15px;
		font-weight: bold;
		letter-spacing: normal;
	}

	&:hover {
		background: #ccc;
	}
`;



export default class Calculator extends Component {

	state = {
		step: 1,
		device_number: 2,
		devices: [true, true, true, true, true],
		names: ["", "", "", "", ""],
		lengths: ["", "", "", "", ""],
		angles: ["", "", "", "", ""],
		weights: ["", "", "", "", ""],
		patient_first_name: "",
		patient_last_name: "",
		eye: "",
		equally_weighted: true,
		resultant_right: [],
		resultant_left: [],
		error: "",
		bothEyes: false,
		right: "",
		left: ""
	};

	digits = "1234567890.";

	UNSAFE_componentWillMount() {
		var info = JSON.parse( window.localStorage.getItem("right_eye_info") );
		if(info !== null) {
			info.angles = this.state.angles;
			info.lengths = this.state.lengths;
			window.localStorage.setItem("right_eye_info", JSON.stringify(info));
		}
		info = JSON.parse( window.localStorage.getItem("left_eye_info") );
		if(info !== null) {
			info.angles = this.state.angles;
			info.lengths = this.state.lengths;
			window.localStorage.setItem("left_eye_info", JSON.stringify(info));
		}

		var data = window.localStorage.getItem("data");
		data = JSON.parse(data);
		if(data !== null) {
			if(data.imp !== undefined) {
				window.localStorage.removeItem("data");
				return;
			}

			this.setState({
				patient_first_name: data.patient_first_name,
				patient_last_name: data.patient_last_name,
				eye: data.eye
			});

			if(data.eye === "Right Eye") info = window.localStorage.getItem("right_eye_info");
			else info = window.localStorage.getItem("left_eye_info");
			info = JSON.parse(info);
			if(info != null) {
				this.setState({
					device_number: info.device_number,
					names: info.names,
					weights: info.weights,
					equally_weighted: info.equally_weighted,
					step: 2
				});
			}
		}
	}

	saveAndNextStep = () => {
		var eye = (this.state.eye === "Right Eye") ? "right_eye_info" : "left_eye_info";
		var info = JSON.parse( window.localStorage.getItem(eye) );
		if(info === null || info === undefined) info = {};
		info.device_number = this.state.device_number;
		info.names = this.state.names;
		info.weights = this.state.weights;
		info.equally_weighted = this.state.equally_weighted;
		window.localStorage.setItem(eye, JSON.stringify(info));

		this.nextStep();

	}

	nextStep = () => {

		if(this.state.equally_weighted === true) {
			var n = this.state.device_number;
			let val = (100/n).toFixed(2);
			let _weights = ["", "", "", "", ""];
			for( var i=0; i<n; i++ ) {
				_weights[i] = val;
			}
			this.setState({weights: _weights});
		}
		else{
			var n = this.state.device_number;
			var w = [,,,,];
			var sumWeights = 0;
			for(var i=0; i<n; i++) {
				w[i] = (this.state.weights[i] !== "") ? parseFloat(this.state.weights[i]) : 0.00;
				sumWeights += w[i];
			}
			if(sumWeights !== 100) {
				this.setState({error: "The sum of the weights must be 100."});
				return;
			}
		}

		var data = {
			patient_first_name: this.state.patient_first_name,
			patient_last_name: this.state.patient_last_name,
			eye: this.state.eye
		}
		window.localStorage.setItem("data", JSON.stringify(data));

		this.setState({step: 2, error: ""});
	}

	calculate = () => {
		this.setState({ step: 3 });

		var data = {
			patient_first_name: this.state.patient_first_name,
			patient_last_name: this.state.patient_last_name,
			eye: this.state.eye
		}
		window.localStorage.setItem("data", JSON.stringify(data));

		var eye = (this.state.eye === "Right Eye") ? "right_eye_info" : "left_eye_info";
		var info = window.localStorage.getItem(eye);
		info = JSON.parse(info);
		info.angles = this.state.angles;
		info.lengths = this.state.lengths;
		window.localStorage.setItem(eye, JSON.stringify(info));

		if(eye === "right_eye_info") this.setState({resultant_right: this.getResultant(), right: info});
		else this.setState({resultant_left: this.getResultant(), left: info});
	}

	getResultant = () => {
		var n = this.state.device_number;
		var l = [,,,,];
		var a = [,,,,];
		var w = [,,,,];
		for(var i=0; i<n; i++) {
			l[i] = (this.state.lengths[i] !== "") ? parseFloat(this.state.lengths[i]) : 0.00;
			a[i] = (this.state.angles[i] !== "") ? parseFloat(this.state.angles[i]) : 0.00;
			w[i] = (this.state.weights[i] !== "") ? parseFloat(this.state.weights[i])/100 : 0.00;
		}

		if(n === 1) {
			return [l[0], a[0]];
		}

		var X = 0;
		var Y = 0;
		for(var i = 0; i < n; i++) {
			X += l[i] * w[i] * Math.cos(a[i] * Math.PI / 180);
			Y += l[i] * w[i] * Math.sin(a[i] * Math.PI / 180);
		}
		var r = Math.sqrt(X * X + Y * Y).toFixed(2);

		while(r < 0.50 && n > 2) {
			var index = this.getDeviceWithSmallestMagnitude(l, n);
			l = this.deleteElement(l, n, index);
			a = this.deleteElement(a, n, index);
			w = this.getNewWeights(w, n, index);
			n--;

			X = 0;
			Y = 0;
			for(var i = 0; i < n; i++) {
				X += l[i] * w[i] * Math.cos(a[i] * Math.PI / 180);
				Y += l[i] * w[i] * Math.sin(a[i] * Math.PI / 180);
			}
			r = Math.sqrt(X * X + Y * Y).toFixed(2);
		}

		if(r < 0.50 && n === 2) {
			var index = this.getDeviceWithSmallestMagnitude(l, n);
			l = this.deleteElement(l, n, index);
			a = this.deleteElement(a, n, index);
			w = this.getNewWeights(w, n, index);
			n--;

			return [l[0], a[0]];
		}

		if(r !== 0.00) {
			var R = ( Math.atan(Y/X) / Math.PI * 180);
			if(R < 0) R += 180;
			R = R.toFixed(2);
		}
		else var R = "no axis";

		return [r, R];
	}

	getDeviceWithSmallestMagnitude = (arr, n) => {
		var min = arr[0];
		var index = 0;
		for(var i = 1; i < n; i++) {
			if(arr[i] < min) {
				min = arr[i];
				index = i;
			}
		}
		return index;
	}

	deleteElement = (arr, n, index) => {
		for(var i = index; i < n; i++) {
			arr[i] = arr[i + 1];
		}
		arr[n - 1]= 0.00;
		return arr;
	}

	getNewWeights = (weights, n, index) => {
		var restOfWeights = 1 - weights[index];
		var newSum = 0;
		weights = this.deleteElement(weights, n, index);
		n--;
		for(var i = 0; i < n - 1; i++) {
			weights[i] = weights[i]/restOfWeights;
			newSum += weights[i];
		}
		weights[n - 1] = 1 - newSum;
		return weights;
	}

	collapseDevices = (index) => {
		let _devices = this.state.devices;
		_devices[index] = !this.state.devices[index];
		this.setState({ devices: _devices });
	}

	handleChangeSelect = (event) => {
		let value = event.target.value;
		this.setState({device_number: value});
	}

	validateNumericInput = (value) => {
		var char = value.slice(-1);
		if(this.digits.indexOf(char) === -1) return false;
		if(char === "." && (value.slice(0,-1)).indexOf('.') !== -1) return false;
		return true;
	}

	changeName = (event) => {
		let index = event.target.name;
		let value = event.target.value;

		let _names = this.state.names;
		_names[index] = value;
	    this.setState({names: _names});
	}

	changeLength = (event) => {
		let index = event.target.name;
		let value = event.target.value;

		if(value === ".") value = "0.";
		if(value.length > 7) return;
		if( !this.validateNumericInput(value) ) return;

		let _lengths = this.state.lengths;
		_lengths[index] = value;
	    this.setState({lengths: _lengths});
	}

	changeAngle = (event) => {
		let index = event.target.name;
		let value = event.target.value;

		if(value === ".") value = "0.";
		if(value.length > 7) return;
		if( !this.validateNumericInput(value) ) return;

		let _angles = this.state.angles;
		_angles[index] = value;
	    this.setState({angles: _angles});
	}

	changeWeight = (event) => {
		if(this.state.equally_weighted === true) return;
		let index = event.target.name;
		let value = event.target.value;

		if(value === ".") value = "0.";
		if(value.length > 4) return;
		if( !this.validateNumericInput(value) ) return;

		let _weights = this.state.weights;
		_weights[index] = value;
	    this.setState({weights: _weights});
	}

	chooseEye = (eye) => {
		if(eye === "Right") var info = window.localStorage.getItem("right_eye_info");
		else var info = window.localStorage.getItem("left_eye_info");
		info = JSON.parse(info);
		if(info !== null) {
			this.setState({
				device_number: info.device_number,
				names: info.names,
				weights: info.weights,
				equally_weighted: info.equally_weighted,
				angles: info.angles,
				lengths: info.lengths,
				eye: eye + " Eye"
			});
		}
		else this.setState({ eye: eye + " Eye"});
	}

	equally_weighted = (value) => {
		this.setState({equally_weighted: value});
	}

	onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

	revise = () => {
		this.setState({step: 1});
	}

	calculateForOtherEye = () => {
		if(this.state.eye !== "Right Eye") var info = window.localStorage.getItem("right_eye_info");
		else var info = window.localStorage.getItem("left_eye_info");
		info = JSON.parse(info);
		if(info !== null) {
			this.setState({
				device_number: info.device_number,
				names: info.names,
				weights: info.weights,
				equally_weighted: info.equally_weighted,
				angles: info.angles,
				lengths: info.lengths,
				eye: (this.state.eye === "Left Eye") ? "Right Eye" : "Left Eye",
				step: 1,
				bothEyes: true
			});
		}
		else {
			this.setState({
				step: 1,
				bothEyes: true,
				lengths: ["", "", "", "", ""],
				angles: ["", "", "", "", ""],
				eye: (this.state.eye === "Left Eye") ? "Right Eye" : "Left Eye"
			});
		}
	}

	editCalculus = (eye) => {
		if(eye === "Right") var info = window.localStorage.getItem("right_eye_info");
		else var info = window.localStorage.getItem("left_eye_info");
		info = JSON.parse(info);
		if(info !== null) {
			this.setState({
				device_number: info.device_number,
				names: info.names,
				weights: info.weights,
				equally_weighted: info.equally_weighted,
				angles: info.angles,
				lengths: info.lengths,
				eye: eye + " Eye",
				step: 2
			});
		}
	}

	getCurrentDate = () => {
		let newDate = new Date()
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();

		return month + '/' + date + '/' + year;
	}

	render () {
		return ( 
		<div>
		<Title >Astigmatism Device Calculator</Title>
		{this.state.step <= 2 ?
		(<div>
			<Container>
				<Paragraph>
					Patient Information
				</Paragraph>
				<Row>
					<HalfRow>
						<Label>First Name</Label>
						<Input type="text" name="patient_first_name" value={this.state.patient_first_name} onChange={this.onChange} placeholder="First Name" />
					</HalfRow>
					<HalfRow>
						<Label>Last Name</Label>
						<Input type="text" name="patient_last_name" value={this.state.patient_last_name} onChange={this.onChange} placeholder="Last Name" />
					</HalfRow>
				</Row>
				<Label style={{marginBottom: "15" + "px"}}>Patient Eye</Label>
				<LabelRadio onClick={() => this.chooseEye("Left")}>
					<input type="radio" name="eye" checked={this.state.eye === "Left Eye"}/>Left Eye
					<span></span>
				</LabelRadio>
				<LabelRadio onClick={() => this.chooseEye("Right")}>
					<input type="radio" name="eye" checked={this.state.eye === "Right Eye"}/>Right Eye
					<span></span>
				</LabelRadio>
			</Container>

			<Container>
				{this.state.step === 1 && <div>
					<Paragraph2>How many devices do you have?</Paragraph2>
					<HalfRow>
						<Select name="device_number" value={this.state.device_number} onChange={this.handleChangeSelect}>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
						</Select>
					</HalfRow>
					<br/>

					<Paragraph2>Enter the name of your devices:</Paragraph2>
					<Row>
						<NameLabel>Device #1:</NameLabel> 
						<ShortInput type="text" name="0" value={this.state.names[0]} onChange={this.changeName} />
					</Row>
					{this.state.device_number > 1 &&
					<Row>
						<NameLabel>Device #2:</NameLabel> 
						<ShortInput type="text" name="1" value={this.state.names[1]} onChange={this.changeName} />
					</Row>
					}
					{this.state.device_number > 2 &&
					<Row>
						<NameLabel>Device #3:</NameLabel> 
						<ShortInput type="text" name="2" value={this.state.names[2]} onChange={this.changeName} />
					</Row>
					}
					{this.state.device_number > 3 &&
					<Row>
						<NameLabel>Device #4:</NameLabel> 
						<ShortInput type="text" name="3" value={this.state.names[3]} onChange={this.changeName} />
					</Row>
					}
					{this.state.device_number > 4 &&
					<Row>
						<NameLabel>Device #5:</NameLabel> 
						<ShortInput type="text" name="4" value={this.state.names[4]} onChange={this.changeName} />
					</Row>
					}
					<br/>

					<Paragraph2>Should they be weighted equally?</Paragraph2>
					<LabelRadio onClick={() => this.equally_weighted(true)}>
						<input type="radio" name="equally_weighted" checked={this.state.equally_weighted}/>Yes
						<span></span>
					</LabelRadio>
					<LabelRadio onClick={() => this.equally_weighted(false)}>
						<input type="radio" name="equally_weighted" checked={!this.state.equally_weighted}/>No
						<span></span>
					</LabelRadio>
					<br/>

					{!this.state.equally_weighted &&
					<div>
						{this.state.error !== "" && <Error>{this.state.error}</Error> }

						<Paragraph2>Please fill the weight percentage of each device</Paragraph2>
						<Label>Note: The sum of the percentages has to be 100%</Label>

						<Row>
							<NameLabel>{this.state.names[0]}:</NameLabel> 
							<ShortInput type="text" name="0" value={this.state.weights[0]} onChange={this.changeWeight} placeholder="Enter Weight" />
						</Row>
						{this.state.device_number > 1 && <Row>
							<NameLabel>{this.state.names[1]}:</NameLabel> 
							<ShortInput type="text" name="1" value={this.state.weights[1]} onChange={this.changeWeight} placeholder="Enter Weight" />
						</Row>}
						{this.state.device_number > 2 && <Row>
							<NameLabel>{this.state.names[2]}:</NameLabel> 
							<ShortInput type="text" name="2" value={this.state.weights[2]} onChange={this.changeWeight} placeholder="Enter Weight" />
						</Row>}
						{this.state.device_number > 3 && <Row>
							<NameLabel>{this.state.names[3]}:</NameLabel> 
							<ShortInput type="text" name="3" value={this.state.weights[3]} onChange={this.changeWeight} placeholder="Enter Weight" />
						</Row>}
						{this.state.device_number > 4 && <Row>
							<NameLabel>{this.state.names[4]}:</NameLabel> 
							<ShortInput type="text" name="4" value={this.state.weights[4]} onChange={this.changeWeight} placeholder="Enter Weight" />
						</Row>}
					</div> 
					}

					{this.state.step === 1 && <div>
						<Button2 onClick={this.saveAndNextStep}>
							<p>Save these settings and go to the next step</p>
						</Button2>
						<Button2 onClick={this.nextStep}>
							<p>Go to the next step without saving these settings</p>
						</Button2>
					</div> }

				</div> 
				}

				
				{this.state.step === 2 && <div>

					<Button2 onClick={this.revise}>
						<p>Go back and revise the settings</p>
					</Button2>

					<Paragraph>
						{this.state.eye} Measurements
					</Paragraph>

					<Paragraph className="collapse_parent"  onClick={() => this.collapseDevices(0)}>
						<p className="collapse">
							{this.state.devices[0] === true && <p>&ndash;</p> }
							{this.state.devices[0] === false && <p>+</p> }
						</p>
						<p className="collapse_label">{this.state.names[0]}</p>
					</Paragraph>
					{this.state.devices[0] === true &&
					<Row>
						<QuarterRow>
							<Label>Magnitude</Label>
							<Input type="text" name="0" value={this.state.lengths[0]} onChange={this.changeLength} placeholder="Enter" />
						</QuarterRow>
						<QuarterRow>
							<Label>@ Degree</Label>
							<Input type="text" name="0" value={this.state.angles[0]} onChange={this.changeAngle} placeholder="Enter" />
						</QuarterRow>
					</Row>
					}

					{this.state.device_number > 1 &&
					<div>
						<Paragraph className="collapse_parent"  onClick={() => this.collapseDevices(1)}>
							<p className="collapse">
								{this.state.devices[1] === true && <p>&ndash;</p> }
								{this.state.devices[1] === false && <p>+</p> }
							</p>
							<p className="collapse_label">{this.state.names[1]}</p>
						</Paragraph>
						{this.state.devices[1] === true &&
						<Row>
							<QuarterRow>
								<Label>Magnitude</Label>
								<Input type="text" name="1" value={this.state.lengths[1]} onChange={this.changeLength} placeholder="Enter" />
							</QuarterRow>
							<QuarterRow>
								<Label>@ Degree</Label>
								<Input type="text" name="1" value={this.state.angles[1]} onChange={this.changeAngle} placeholder="Enter" />
							</QuarterRow>
						</Row>
						}
					</div>
					}

					{this.state.device_number > 2 &&
					<div>
						<Paragraph className="collapse_parent"  onClick={() => this.collapseDevices(2)}>
							<p className="collapse">
								{this.state.devices[2] === true && <p>&ndash;</p> }
								{this.state.devices[2] === false && <p>+</p> }
							</p>
							<p className="collapse_label">{this.state.names[2]}</p>
						</Paragraph>
						{this.state.devices[2] === true &&
						<Row>
							<QuarterRow>
								<Label>Magnitude</Label>
								<Input type="text" name="2" value={this.state.lengths[2]} onChange={this.changeLength} placeholder="Enter" />
							</QuarterRow>
							<QuarterRow>
								<Label>@ Degree</Label>
								<Input type="text" name="2" value={this.state.angles[2]} onChange={this.changeAngle} placeholder="Enter" />
							</QuarterRow>
						</Row>
						}
					</div>
					}

					{this.state.device_number > 3 &&
					<div>
						<Paragraph className="collapse_parent"  onClick={() => this.collapseDevices(3)}>
							<p className="collapse">
								{this.state.devices[3] === true && <p>&ndash;</p> }
								{this.state.devices[3] === false && <p>+</p> }
							</p>
							<p className="collapse_label">{this.state.names[3]}</p>
						</Paragraph>
						{this.state.devices[3] === true &&
						<Row>
							<QuarterRow>
								<Label>Magnitude</Label>
								<Input type="text" name="3" value={this.state.lengths[3]} onChange={this.changeLength} placeholder="Enter" />
							</QuarterRow>
							<QuarterRow>
								<Label>@ Degree</Label>
								<Input type="text" name="3" value={this.state.angles[3]} onChange={this.changeAngle} placeholder="Enter" />
							</QuarterRow>
						</Row>
						}
					</div>
					}

					{this.state.device_number > 4 &&
					<div>
						<Paragraph className="collapse_parent"  onClick={() => this.collapseDevices(4)}>
							<p className="collapse">
								{this.state.devices[4] === true && <p>&ndash;</p> }
								{this.state.devices[4] === false && <p>+</p> }
							</p>
							<p className="collapse_label">{this.state.names[4]}</p>
						</Paragraph>
						{this.state.devices[4] === true &&
						<Row>
							<QuarterRow>
								<Label>Magnitude</Label>
								<Input type="text" name="4" value={this.state.lengths[4]} onChange={this.changeLength} placeholder="Enter" />
							</QuarterRow>
							<QuarterRow>
								<Label>@ Degree</Label>
								<Input type="text" name="4" value={this.state.angles[4]} onChange={this.changeAngle} placeholder="Enter" />
							</QuarterRow>
						</Row>
						}
					</div>
					}
				</div>
				}
			</Container>

			{this.state.step === 2 &&
				<Button onClick={this.calculate}><p>Calculate</p></Button>
			}
		</div>):(
		<div>
			<PrintButton id="printButton" href="javascript:window.print();">
				<i className="fa fa-print"></i>
			</PrintButton>
			<Container>
				<Row>
					<HalfRow>
						<ResultsInfo bold="bold">
							Date: {this.getCurrentDate()}
						</ResultsInfo>
						<ResultsInfo bold="bold">
							Patient: {this.state.patient_first_name} {this.state.patient_last_name}
						</ResultsInfo>
					</HalfRow>
				</Row>
				<Separator />
				<Row>
					<HalfRow>
						{this.state.resultant_right.length !== 0 && <div>
							<EyeResults onClick={() => this.editCalculus("Right")} bold="bold" hover><i className="fa fa-edit"></i> OD:</EyeResults>
							<div style={{marginLeft: 40 + 'px'}}>
								{this.state.right.lengths[0] && <ResultsInfo>{this.state.right.names[0]} ({this.state.right.weights[0]}%): {this.state.right.lengths[0]}@{this.state.right.angles[0]}</ResultsInfo>}
								{this.state.right.lengths[1] && <ResultsInfo>{this.state.right.names[1]} ({this.state.right.weights[1]}%): {this.state.right.lengths[1]}@{this.state.right.angles[1]}</ResultsInfo>}
								{this.state.right.lengths[2] && <ResultsInfo>{this.state.right.names[2]} ({this.state.right.weights[2]}%): {this.state.right.lengths[2]}@{this.state.right.angles[2]}</ResultsInfo>}
								{this.state.right.lengths[3] && <ResultsInfo>{this.state.right.names[3]} ({this.state.right.weights[3]}%): {this.state.right.lengths[3]}@{this.state.right.angles[3]}</ResultsInfo>}
								{this.state.right.lengths[4] && <ResultsInfo>{this.state.right.names[4]} ({this.state.right.weights[4]}%): {this.state.right.lengths[4]}@{this.state.right.angles[4]}</ResultsInfo>}
								<ResultsInfo>Resultant: {this.state.resultant_right[0]}@{this.state.resultant_right[1]}</ResultsInfo>
							</div>
						</div>
						}
						{this.state.resultant_left.length !== 0 && <div>
							<EyeResults onClick={() => this.editCalculus("Left")} bold="bold" hover><i className="fa fa-edit"></i> OS:</EyeResults>
							<div style={{marginLeft: 40 + 'px'}}>
								{this.state.left.lengths[0] && <ResultsInfo>{this.state.left.names[0]} ({this.state.left.weights[0]}%): {this.state.left.lengths[0]}@{this.state.left.angles[0]}</ResultsInfo>}
								{this.state.left.lengths[1] && <ResultsInfo>{this.state.left.names[1]} ({this.state.left.weights[1]}%): {this.state.left.lengths[1]}@{this.state.left.angles[1]}</ResultsInfo>}
								{this.state.left.lengths[2] && <ResultsInfo>{this.state.left.names[2]} ({this.state.left.weights[2]}%): {this.state.left.lengths[2]}@{this.state.left.angles[2]}</ResultsInfo>}
								{this.state.left.lengths[3] && <ResultsInfo>{this.state.left.names[3]} ({this.state.left.weights[3]}%): {this.state.left.lengths[3]}@{this.state.left.angles[3]}</ResultsInfo>}
								{this.state.left.lengths[4] && <ResultsInfo>{this.state.left.names[4]} ({this.state.left.weights[4]}%): {this.state.left.lengths[4]}@{this.state.left.angles[4]}</ResultsInfo>}
								<ResultsInfo>Resultant: {this.state.resultant_left[0]}@{this.state.resultant_left[1]}</ResultsInfo>
							</div>
						</div>
						}
					</HalfRow>
				</Row>
				<br />
				<Row>
					<HalfRow>
						<Button2 className="no_print" onClick={()=>this.setState({step: 1})}>
							<p>Go Back and Revise Entries</p>
						</Button2>
					</HalfRow>
					{this.state.bothEyes === false &&
					<HalfRow>
						<Button2 className="no_print" onClick={this.calculateForOtherEye}>
							<p>Calculate for the { (this.state.eye === "Left Eye") ? "right eye" : "left eye"}</p>
						</Button2>
					</HalfRow>
					}
				</Row>
			</Container>
		</div>
		)}
	</div>
	)}
}
