import React from 'react';
import styled from 'styled-components';
import './style.less';

const Title = styled.p`
	color: #444;
	font-size  37px;
	font-weight: bold;
	margin: 0px auto 40px;
	width: 100%;
`;

const Paragraph = styled.p`
	color: #444;
	font-size: 24px;
	margin: 0.5rem;
`;

const ParagraphSmall = styled.p`
	color: #444;
	font-size: 18px;
	margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`;

class Contact extends React.Component {

  render() {
    return (
    <div className="container text-center">
        <Title>Contact</Title>
        <ParagraphSmall>Contact: Dr. Nathan Rock, <a href="mailto:nathan@wangvisioninstitute.com">nathan@wangvisioninstitute.com</a>, 615-321-8881</ParagraphSmall>
        <ParagraphSmall>Ming Wang, MD, PhD, Dr. Joshua Frenkel, MD, MPH<br />Drs. Sarah Connolly, OD, Marianne Johnson, OD, and Nathan Rock, OD</ParagraphSmall>
        <br/>
        <Paragraph><b>Wang Vision Institute</b> <a href="http://www.wangvisioninstitute.com">(see here)</a></Paragraph>
        <ParagraphSmall>1801 West End Ave Ave, Ste 1150, Nashville, TN, 37203, USA <br/>615-321-8881 (O), 615-321-8874(Fax)</ParagraphSmall>
        <ParagraphSmall><a href="http://www.wangvisioninstitute.com">www.wangvisioninstitute.com</a></ParagraphSmall>
    </div>
    )
  }
}

export default Contact