import React from 'react'
import styled from 'styled-components';
import './style.less'

import aboutUs from './images/about-us.jpg';

const Title = styled.p`
	color: #444;
	font-size  37px;
	font-weight: bold;
	margin: 0px auto 40px;
  width: 100%;
  text-align: center;
`;

const Paragraph = styled.p`
	color: #444;
	font-size: 18px;
	margin: 0;
  margin-bottom: 1.5rem;
`;

const Caption = styled.span`
  color: #999;
  font-size: 16px;
`;

class AboutUs extends React.Component {

  render() {
    return (
    <div className="container">
        <Title>About this program and about us</Title>
        <Paragraph>Various different corneal topography (placido, tomography, etc) and biometry devices often produces different amount and also axes of corneal astigmatism. However, surgeons need to know the most accurate corneal astigmatism value to put into their corneal refractive and lens refractive treatments. In addition, various devices also have different degree of validity and efficacy.</Paragraph>

		    <Paragraph>This program allows a surgeon to input all corneal astigmatism values measured by different devices, and arrive at the optimal and most accurate value of corneal astigmatism.</Paragraph>

        <p className="about-us-image">
            <img src={aboutUs} alt="5-doctors" title="Drs. Sarah Connolly, OD, Ming Wang, MD, PhD, Joshua Frenkel, MD, MPH, Nathan Rock, OD, and Marianne Johnson, OD" />
            <Caption>Drs. Sarah Connolly, OD, Ming Wang, MD, PhD, Joshua Frenkel, MD, MPH, Nathan Rock, OD, and Marianne Johnson, OD</Caption>
        </p>

        <Paragraph>We at Wang Vision Institute (see <a href="https://www.wangvisioninstitute.com/index.html">here</a>) have devoted ourselves to the study of corneal topography and astigmatism for 3 decades and have published 9 textbooks including the second best seller of all time by SLACK, Inc “Corneal Topography in the Wavefront Era”, 1<sup>st</sup> and second editions (see <a href="https://www.wangvisioninstitute.com/publications_corneal_topography_v2.html">here</a>).</Paragraph>

        <Paragraph><b><u>Dr. Ming Wang</u></b>, Harvard & MIT (MD, magna cum laude); PhD (laser physics), is the CEO of Aier-USA and Director of Wang Vision Institute. He has received many honors including the Honor Award from the American Academy of Ophthalmology, the Lifetime Achievement Award from the Association of Chinese American Physicians, and Kiwanis Nashvillian of the Year Award for this lifetime dedication to help blind orphan children worldwide.</Paragraph>

        <Paragraph><b><u>Dr. Joshua Frenkel</u></b>, MD, MPH received training in microsurgical glaucoma surgeries from the #1 training program in the country. He graduated with an MD and MPH and completed his ophthalmology residency from the prestigious Tulane University.</Paragraph>

        <Paragraph><b><u>Wang Vision doctors</u> (Drs. Sarah Connolly, Joshua Frenkel, Marianne Johnson, Nathan Rock and Ming Wang)</b> have performed over 55,000 procedures (including on over 4,000 doctors). They have published nine textbooks, hold several U.S. patents and performed the world’s first laser artificial cornea implantation. Wang Vision is currently the only center in the state that performs 3D SMILE (small-incision laser vision correction) & 3D LASIK (18+), 3D Implantable Contact Lens (21+), 3D Forever Young Lens (45+) and 3D Laser Cataract Surgery (60+). It has founded a 501(c)(3) nonprofit charity, Wang Foundation for Sight Restoration, which to date has helped patients from over 40 states in the U.S. and 55 countries with all sight restoration surgeries performed free-of-charge.</Paragraph>
    </div>
    )
  }
}

export default AboutUs